import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { useFormik } from 'formik';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import showNotification from '../../../components/extras/showNotification';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
import useDarkMode from '../../../hooks/useDarkMode';
import useLocalStorage from '../../../hooks/useLocalStorage';
import { getRoleById, loginUser } from '../../../common/data/getData';
import { demoPages } from '../../../menu';

const validate = (values) => {
	const errors = {};

	if (!values.userName) {
		errors.userName = 'user name is required';
	}

	if (!values.password) {
		errors.password = 'password is required';
	}

	return errors;
};

const Login = () => {
	const { darkModeStatus } = useDarkMode();
	const navigate = useNavigate();
	const { user, setLocalAuthValue: setUser, setRoles } = useLocalStorage();

	useEffect(() => {
		if (user?.roleId) {
			getRoleById(user.roleId).then((anotherdata) => {
				const listOfRoles = anotherdata.data.data.permission;
				listOfRoles.unshift('/dashboard');
				setRoles(listOfRoles);
				navigate('/dashboard');
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);

	useEffect(() => {
		if (user && user.token) {
			navigate('/dashboard');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function postData(values) {
		loginUser(values)
			.then((res) => {
				const { data } = res;
				setUser(data.data);
			})
			.catch((error) => {
				console.log(error);
				showNotification(
					<span className='d-flex align-items-center'>
						<span>{error.response.data.message}</span>
					</span>,
					'',
					'danger',
				);
			});
	}
	const formik = useFormik({
		initialValues: {
			userName: '',
			password: '',
		},
		onSubmit: (values) => {
			postData({
				email: values.userName,
				password: values.password,
			});
		},
		validate,
	});

	return (
		<PageWrapper title='Login' className='bg-info'>
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card className='shadow-3d-dark' data-tour='login-page'>
							<CardBody>
								<div className='text-center my-5'>
									<div
										className={classNames(
											'text-decoration-none  fw-bold display-2',
											{
												'text-dark': !darkModeStatus,
												'text-light': darkModeStatus,
											},
										)}>
										<div className='text-center h1 fw-bold mt-5'>Perks99</div>
										<div className='text-center h4 text-muted mb-5'>
											Sign in to continue!
										</div>
									</div>
								</div>
								<div className='row g-4'>
									<div className='col-12'>
										<FormGroup id='userName' isFloating label='Email'>
											<Input
												type='text'
												onChange={formik.handleChange}
												value={formik.values.userName}
												isValid={formik.isValid}
												isTouched={formik.touched.userName}
												invalidFeedback={formik.errors.userName}
												validFeedback='Looks good!'
											/>
										</FormGroup>
									</div>
									<div className='col-12'>
										<FormGroup id='password' isFloating label='Password'>
											<Input
												type='password'
												onChange={formik.handleChange}
												value={formik.values.password}
												isValid={formik.isValid}
												isTouched={formik.touched.password}
												invalidFeedback={formik.errors.password}
												validFeedback='Looks good!'
											/>
										</FormGroup>
									</div>
									<div className='col-12'>
										<div className='text-end'>
											<Link
												to={demoPages.forgotPassword.path}
												style={{
													color: '#000',
													textDecoration: 'underline',
												}}>
												Forgot password?{' '}
											</Link>
										</div>
									</div>
									<div className='col-12'>
										<Button
											color='info'
											className='w-100 py-3'
											onClick={formik.handleSubmit}>
											Login
										</Button>
									</div>
								</div>
							</CardBody>
						</Card>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default Login;
