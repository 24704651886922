import React, { lazy, useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import contents from '../../routes/contentRoutes';
import useLocalStorage from '../../hooks/useLocalStorage';

const PAGE_404 = lazy(() => import('../../pages/presentation/auth/Page404'));

const ContentRoutes = () => {
	const { user } = useLocalStorage();
	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		if (location.pathname.includes('changepassword')) return;
		if (location.pathname === '/forgotpassword') return;
		if (!user || !user.token) navigate('/');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user, navigate]);

	return (
		<Routes>
			{contents.map((page) => (
				// eslint-disable-next-line react/jsx-props-no-spreading
				<Route key={page.path} {...page} />
			))}
			<Route path='*' element={<PAGE_404 />} />
		</Routes>
	);
};

export default ContentRoutes;
