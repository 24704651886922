// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-unused-vars */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody, CardLabel, CardTitle } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
import showNotification from '../../../components/extras/showNotification';
import { loginUser } from '../../../common/data/getData';
import { demoPages } from '../../../menu';
import SubHeader, { SubHeaderRight } from '../../../layout/SubHeader/SubHeader';
import useLocalStorage from '../../../hooks/useLocalStorage';

const validate = (values) => {
	const errors = {};

	if (!values.userName) {
		errors.userName = 'user name is required';
	}

	if (!values.password) {
		errors.password = 'password is required';
	}

	return errors;
};

const ChangePassword = () => {
	const navigate = useNavigate();
	const { setLocalAuthValue } = useLocalStorage();

	function postData(values) {
		loginUser(values)
			.then((res) => {
				const { data } = res;
				setLocalAuthValue(data.data);
				navigate('/dashboard');
			})
			.catch((error) => {
				console.log(error);
				showNotification(
					<span className='d-flex align-items-center'>
						<span>{error.response.data.message}</span>
					</span>,
					'',
					'danger',
				);
			});
	}

	const formik = useFormik({
		initialValues: {
			userName: '',
			password: '',
		},
		onSubmit: (values) => {
			postData({
				userName: values.userName,
				password: values.password,
			});
		},
		validate,
	});

	return (
		<PageWrapper title={demoPages.changePassword.text}>
			<SubHeader>
				<SubHeaderRight>
					<CardLabel>
						<CardTitle tag='h3'>CHANGE PASSWORD</CardTitle>
					</CardLabel>
				</SubHeaderRight>
			</SubHeader>
			<Page container='fluid'>
				<Card>
					<CardBody>
						<div className='row g-4'>
							<div className='col-12'>
								<FormGroup id='oldPassword' isFloating label='Old password'>
									<Input
										type='password'
										onChange={formik.handleChange}
										value={formik.values.password}
										isValid={formik.isValid}
										isTouched={formik.touched.password}
										invalidFeedback={formik.errors.password}
										validFeedback='Looks good!'
									/>
								</FormGroup>
							</div>
							<div className='col-12'>
								<FormGroup id='newPassword' isFloating label='New password'>
									<Input
										type='password'
										onChange={formik.handleChange}
										value={formik.values.password}
										isValid={formik.isValid}
										isTouched={formik.touched.password}
										invalidFeedback={formik.errors.password}
										validFeedback='Looks good!'
									/>
								</FormGroup>
							</div>
							<div className='col-12'>
								<FormGroup id='password' isFloating label='Confirm password'>
									<Input
										type='password'
										onChange={formik.handleChange}
										value={formik.values.password}
										isValid={formik.isValid}
										isTouched={formik.touched.password}
										invalidFeedback={formik.errors.password}
										validFeedback='Looks good!'
									/>
								</FormGroup>
							</div>
							<div className='col-12'>
								<Button
									color='info'
									className='w-100 py-3'
									onClick={formik.handleSubmit}>
									Submit
								</Button>
							</div>
						</div>
					</CardBody>
				</Card>
			</Page>
		</PageWrapper>
		// <PageWrapper title='Login' className='bg-info'>
		// 	<Page className='p-0'>
		// 		<div className='row h-100 align-items-center justify-content-center'>
		// 			<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
		// 				<Card className='shadow-3d-dark' data-tour='login-page'>
		// 					<CardBody>
		// 						<div className='text-center my-5'>
		// 							<div
		// 								className={classNames(
		// 									'text-decoration-none  fw-bold display-2',
		// 									{
		// 										'text-dark': !darkModeStatus,
		// 										'text-light': darkModeStatus,
		// 									},
		// 								)}>
		// 								{/* <Logo width={200} /> */}
		// 								<img src={AceLogo} alt='logo' width={200} />
		// 							</div>
		// 						</div>

		// 						<div className='row g-4'>
		// 							<div className='col-12'>
		// 								<FormGroup id='oldPassword' isFloating label='Old password'>
		// 									<Input
		// 										type='password'
		// 										onChange={formik.handleChange}
		// 										value={formik.values.password}
		// 										isValid={formik.isValid}
		// 										isTouched={formik.touched.password}
		// 										invalidFeedback={formik.errors.password}
		// 										validFeedback='Looks good!'
		// 									/>
		// 								</FormGroup>
		// 							</div>
		// 							<div className='col-12'>
		// 								<FormGroup id='newPassword' isFloating label='New password'>
		// 									<Input
		// 										type='password'
		// 										onChange={formik.handleChange}
		// 										value={formik.values.password}
		// 										isValid={formik.isValid}
		// 										isTouched={formik.touched.password}
		// 										invalidFeedback={formik.errors.password}
		// 										validFeedback='Looks good!'
		// 									/>
		// 								</FormGroup>
		// 							</div>
		// 							<div className='col-12'>
		// 								<FormGroup
		// 									id='password'
		// 									isFloating
		// 									label='Confirm password'>
		// 									<Input
		// 										type='password'
		// 										onChange={formik.handleChange}
		// 										value={formik.values.password}
		// 										isValid={formik.isValid}
		// 										isTouched={formik.touched.password}
		// 										invalidFeedback={formik.errors.password}
		// 										validFeedback='Looks good!'
		// 									/>
		// 								</FormGroup>
		// 							</div>
		// 							<div className='col-12'>
		// 								<Button
		// 									color='info'
		// 									className='w-100 py-3'
		// 									onClick={formik.handleSubmit}>
		// 									Submit
		// 								</Button>
		// 							</div>
		// 						</div>
		// 					</CardBody>
		// 				</Card>
		// 			</div>
		// 		</div>
		// 	</Page>
		// </PageWrapper>
	);
};

export default ChangePassword;
