import axios from 'axios';

const baseUrl = process.env.REACT_APP_BACKEND_URL;

axios.defaults.baseURL = baseUrl;

const authHeader = () => {
	const user = JSON.parse(localStorage.getItem('authed'));
	if (user && user.token) {
		return { Authorization: `Bearer ${user.token}` };
	}
	return {};
};

export const loginUser = (values) => {
	return axios.post(`/api/auth/login`, values);
};

export const createUser = (values) => {
	return axios.post(`/api/auth/register`, values);
};

export const getUsers = () => {
	return axios.get(`/api/auth/get`, {
		headers: authHeader(),
	});
};

export const updateUser = (values) => {
	return axios.put(`/api/auth/update/${values.id}`, values, {
		headers: authHeader(),
	});
};

export const deleteUser = (id) => {
	return axios.delete(`/api/auth/delete/${id}`, {
		headers: authHeader(),
	});
};

export const forgotPassword = (values) => {
	return axios.post(`/api/auth/resetpassword`, values);
};

export const resetPassword = (values) => {
	return axios.post(`/api/auth/changepassword/${values.token}`, values, {
		headers: authHeader(),
	});
};

// UploadImage
export const uploadImage = (values) => {
	return axios.post(`/api/upload`, values, {
		headers: { ...authHeader(), 'Content-Type': 'multipart/form-data' },
	});
};

export const removeImage = (values) => {
	return axios.post(`/api/upload/remove`, values, {
		headers: authHeader(),
	});
};

// Roles
export const createRole = (values) => {
	return axios.post(`/api/role/create`, values, {
		headers: authHeader(),
	});
};

export const getRoles = () => {
	return axios.get(`/api/role/get`, {
		headers: authHeader(),
	});
};

export const getRoleById = (id) => {
	return axios.get(`/api/role/get/${id}`, {
		headers: authHeader(),
	});
};

export const deleteRole = (id) => {
	return axios.delete(`/api/role/delete/${id}`, {
		headers: authHeader(),
	});
};

export const updateRole = (values) => {
	return axios.put(`/api/role/update/${values.id}`, values, {
		headers: authHeader(),
	});
};

// banners
export const createBanner = (values) => {
	return axios.post(`/api/banner/create`, values, {
		headers: authHeader(),
	});
};

export const updateBanner = (values) => {
	return axios.put(`/api/banner/update/${values.id}`, values, {
		headers: authHeader(),
	});
};

export const getBanners = () => {
	return axios.get(`/api/banner/getAll`, {
		headers: authHeader(),
	});
};

export const deleteBanner = (values) => {
	return axios.delete(`/api/banner/delete/${values.id}`, {
		headers: authHeader(),
		data: values,
	});
};

// plans
export const createPlan = (values) => {
	return axios.post(`/api/plan/create`, values, {
		headers: authHeader(),
	});
};

export const getPlans = () => {
	return axios.get(`/api/plan/get`, {
		headers: authHeader(),
	});
};

export const getPlanById = (id) => {
	return axios.get(`/api/plan/get/${id}`, {
		headers: authHeader(),
	});
};

export const deletePlan = (values) => {
	return axios.delete(`/api/plan/delete/${values.id}`, {
		headers: authHeader(),
		data: values,
	});
};

export const updatePlan = (values) => {
	return axios.put(`/api/plan/update/${values.id}`, values, {
		headers: authHeader(),
	});
};

// extra items
export const createExtraItem = (values) => {
	return axios.post(`/api/extraItem/create`, values, {
		headers: authHeader(),
	});
};

export const getExtraItems = () => {
	return axios.get(`/api/extraItem/get`, {
		headers: authHeader(),
	});
};

export const getExtraItemById = (id) => {
	return axios.get(`/api/extraItem/get/${id}`, {
		headers: authHeader(),
	});
};

export const deleteExtraItem = (values) => {
	return axios.delete(`/api/extraItem/delete/${values.id}`, {
		headers: authHeader(),
		data: values,
	});
};

export const updateExtraItem = (values) => {
	return axios.put(`/api/extraItem/update/${values.id}`, values, {
		headers: authHeader(),
	});
};

// Restaurant
export const createRestaurant = (values) => {
	return axios.post(`/api/restaurant/create`, values, {
		headers: authHeader(),
	});
};

export const getRestaurant = () => {
	return axios.get(`/api/restaurant/get`, {
		headers: authHeader(),
	});
};

export const getRestaurantCount = () => {
	return axios.get(`/api/restaurant/getCount`, {
		headers: authHeader(),
	});
};

export const getAnalytics = (from, to) => {
	return axios.get(`/api/analytics/getAnalytics?from=${from}&to=${to}`, {
		headers: authHeader(),
	});
};

export const getActiveRestaurant = () => {
	return axios.get(`/api/restaurant/getActiveRestaurant`, {
		headers: authHeader(),
	});
};

export const getRestaurantById = (id) => {
	return axios.get(`/api/restaurant/get/${id}`, {
		headers: authHeader(),
	});
};

export const deleteRestaurant = (values) => {
	return axios.delete(`/api/restaurant/delete/${values.id}`, {
		headers: authHeader(),
		data: values,
	});
};

export const updateRestaurant = (values) => {
	return axios.put(`/api/restaurant/update/${values.id}`, values, {
		headers: authHeader(),
	});
};

// Roles
export const createCity = (values) => {
	return axios.post(`/api/cities/create`, values, {
		headers: authHeader(),
	});
};

export const getCities = () => {
	return axios.get(`/api/cities/get`, {
		headers: authHeader(),
	});
};

export const getCityById = (id) => {
	return axios.get(`/api/cities/get/${id}`, {
		headers: authHeader(),
	});
};

export const deleteCity = (id) => {
	return axios.delete(`/api/cities/delete/${id}`, {
		headers: authHeader(),
	});
};

export const updateCity = (values) => {
	return axios.put(`/api/cities/update/${values.id}`, values, {
		headers: authHeader(),
	});
};

// cuisines
export const createCuisines = (values) => {
	return axios.post(`/api/cuisines/create`, values, {
		headers: authHeader(),
	});
};

export const getCuisines = () => {
	return axios.get(`/api/cuisines/get`, {
		headers: authHeader(),
	});
};

export const getActiveCuisine = () => {
	return axios.get(`/api/cuisines/getActiveCuisine`, {
		headers: authHeader(),
	});
};

export const getCuisinesById = (id) => {
	return axios.get(`/api/cuisines/get/${id}`, {
		headers: authHeader(),
	});
};

export const deleteCuisines = (values) => {
	return axios.delete(`/api/cuisines/delete/${values.id}`, {
		headers: authHeader(),
		data: values,
	});
};

export const updateCuisines = (values) => {
	return axios.put(`/api/cuisines/update/${values.id}`, values, {
		headers: authHeader(),
	});
};

// promo code
export const createPromoCode = (values) => {
	return axios.post(`/api/promoCode/create`, values, {
		headers: authHeader(),
	});
};

export const getPromoCode = () => {
	return axios.get(`/api/promoCode/get`, {
		headers: authHeader(),
	});
};

export const getPromoCodeById = (id) => {
	return axios.get(`/api/promoCode/get/${id}`, {
		headers: authHeader(),
	});
};

export const deletePromoCode = (id) => {
	return axios.delete(`/api/promoCode/delete/${id}`, {
		headers: authHeader(),
	});
};

export const updatePromoCode = (values) => {
	return axios.put(`/api/promoCode/update/${values.id}`, values, {
		headers: authHeader(),
	});
};

// menu
export const createMenu = (values) => {
	return axios.post(`/api/menu/create`, values, {
		headers: authHeader(),
	});
};

export const getMenu = () => {
	return axios.get(`/api/menu/get`, {
		headers: authHeader(),
	});
};

export const getMenuById = (id) => {
	return axios.get(`/api/menu/get/${id}`, {
		headers: authHeader(),
	});
};

export const deleteMenu = (values) => {
	return axios.delete(`/api/menu/delete/${values.id}`, {
		headers: authHeader(),
		data: values,
	});
};

export const updateMenu = (values) => {
	return axios.put(`/api/menu/update/${values.id}`, values, {
		headers: authHeader(),
	});
};

// customer

export const getCustomerCount = () => {
	return axios.get(`/api/customer/getCount`, {
		headers: authHeader(),
	});
};

export const getCustomer = () => {
	return axios.get(`/api/customer/get`, {
		headers: authHeader(),
	});
};
export const getReferralList = (referralId) => {
	return axios.get(`/api/customer/referralList?referralId=${referralId}`, {
		headers: authHeader(),
	});
};
export const getDistinctCity = () => {
	return axios.get(`/api/customer/getDistinctCities`, {
		headers: authHeader(),
	});
};

export const updateCustomer = (values) => {
	return axios.put(`/api/customer/update/${values.id}`, values, {
		headers: authHeader(),
	});
};

// drivers
export const createDriver = (values) => {
	return axios.post(`/api/driver/create`, values, {
		headers: authHeader(),
	});
};

export const getDrivers = () => {
	return axios.get(`/api/driver/get`, {
		headers: authHeader(),
	});
};

export const getDriverById = (id) => {
	return axios.get(`/api/driver/get/${id}`, {
		headers: authHeader(),
	});
};

export const deleteDriver = (values) => {
	return axios.delete(`/api/driver/delete/${values.id}`, {
		headers: authHeader(),
		data: values,
	});
};

export const updateDriver = (values) => {
	return axios.put(`/api/driver/update/${values.id}`, values, {
		headers: authHeader(),
	});
};

// orders
export const createOrder = (values) => {
	return axios.post(`/api/order/create`, values, {
		headers: authHeader(),
	});
};

export const getOrders = (value) => {
	const url = value ? `/api/order/get?status=${value}` : `/api/order/get`;
	return axios.get(`${url}`, {
		headers: authHeader(),
	});
};

export const getOrderById = (id) => {
	return axios.get(`/api/order/get/${id}`, {
		headers: authHeader(),
	});
};

export const deleteOrder = (id) => {
	return axios.delete(`/api/order/delete/${id}`, {
		headers: authHeader(),
	});
};

export const updateOrder = (values) => {
	return axios.put(
		`/api/order/update/${values.orderId}`,
		{ driverId: values.driverId, status: values.status },
		{
			headers: authHeader(),
		},
	);
};
