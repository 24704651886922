import { useContext } from 'react';
import ThemeContext from '../contexts/themeContext';

const useLocalStorage = () => {
	const { localAuthValue, setLocalAuthValue,roles,setRoles  } = useContext(ThemeContext);
	const user = localAuthValue
		? typeof localAuthValue === 'string'
			? JSON.parse(localAuthValue)
			: localAuthValue
		: null;

	return { user, localAuthValue, setLocalAuthValue,roles,setRoles  };
};

export default useLocalStorage;
